.faq {
  display: block;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 10px 0;
  font-family: "Outfit";
  font-size: 20px;
}

.faq a {
  color: inherit;
}

.faq > .faq-checkbox {
  display: none;
}

.faq > .faq-checkbox:checked + .faq-label {
  background: #312cc2;
}

.faq > .faq-checkbox:checked + .faq-label > .faq-arrow {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.faq > .faq-checkbox:checked + .faq-label + .faq-answer {
  display: block;
}

.faq > .faq-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  background: rgba(217, 217, 217, 0.1);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  cursor: pointer;
}

.faq > .faq-label > .faq-question {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.faq > .faq-label > .faq-arrow {
  display: block;
  position: relative;
  float: right;
  margin-right: 10px;
  font-weight: 700;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.faq > .faq-answer {
  display: none;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 300;
  text-align: left;
  padding: 10px 20px;
  border-radius: 30px;
  background: rgba(217, 217, 217, 0.1);
}
