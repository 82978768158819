#footer {
  display: block;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: rgba(255, 255, 255, 0.12);
}

#footer a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

#footer > .footer-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  color: #fff;
}

#footer > .footer-wrapper > .footer-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#footer > .footer-wrapper > .footer-section > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 2em;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 1.2em;
}

#footer > .footer-wrapper > .footer-section > .minor {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 1.5em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 1.2em;
}

#footer > .footer-wrapper > .footer-section > .mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 1.2em;
  font-weight: 300;
  font-family: "Outfit";
  line-height: 1.3em;
  margin-left: 15px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

#footer > .footer-wrapper > .footer-section > .mini:hover {
  text-decoration: underline;
}
