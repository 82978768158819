.track-card {
  display: block;
  width: 40%;
  min-width: 300px;
  height: 30vh;
  min-height: 200px;

  perspective: 1000px;
  cursor: pointer;

  .card-check {
    // the checkbox for flipping the card
    display: none;

    &:checked ~ .track-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.track-card-inner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: none;

  cursor: pointer;
}

.track-card-front,
.track-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;

  border-radius: 0.5em;
}

.track-card-front {
  background: rgba($color: #fff, $alpha: 0.1);
  color: #fff;
}

.track-card-back {
  background: rgba($color: #fff, $alpha: 0.2);
  color: #fff;
  transform: rotateY(180deg);
  font-size: 0.8em;
  text-align: left;
  > .minor {
    font-size: 1.5em;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .track-card {
    .track-card-back {
      font-size: 1.2em;
    }
  }
}
