.faq {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;

    margin: 10px 0;

    font-family: "Outfit";
    font-size: 20px;

    a {
        color: inherit;
    }

    > .faq-checkbox {
        display: none;
    }

    > .faq-checkbox:checked + .faq-label {
        background: #312cc2;
        // box-shadow: 0px 1px 200px #312cc2,
        //     inset 20.0333px -20.0333px 20.0333px rgba(165, 165, 165, 0.1),
        //     inset -20.0333px 20.0333px 20.0333px rgba(255, 255, 255, 0.1);
        > .faq-arrow {
            transform: rotateZ(180deg);
        }
    }

    > .faq-checkbox:checked + .faq-label + .faq-answer {
        display: block;
    }

    > .faq-label {
        display: flex;
        position: relative;
        width: 100%;
        height: fit-content;

        padding: 10px;
        //border-radius: 100px;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        // background-color: rgb(149, 23, 188);
        background: rgba(217, 217, 217, 0.1);
        // box-shadow: inset 20.0333px -20.0333px 20.0333px rgba(165, 165, 165, 0.1),
        //     inset -20.0333px 20.0333px 20.0333px rgba(255, 255, 255, 0.1);
        // backdrop-filter: blur(40.0667px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 30px;

        transition: 0.3s all ease;
        cursor: pointer;

        > .faq-question {
            flex-grow: 1;
        }

        > .faq-arrow {
            display: block;
            position: relative;
            float: right;
            margin-right: 10px;

            font-weight: 700;

            transition: 0.3s all ease;
        }

        // &:hover {
        //     // background: rgba(217, 217, 217, 0.1);
        //     box-shadow: inset 20.0333px -20.0333px 20.0333px rgba(165, 165, 165, 0.1),
        //         inset -20.0333px 20.0333px 20.0333px rgba(255, 255, 255, 0.1),
        //         0px 0px 20px 5px #312cc297;
        //     // backdrop-filter: blur(132.133px);
        //     /* Note: backdrop-filter has minimal browser support */
        // }
    }
    > .faq-answer {
        display: none;
        position: relative;
        width: 100%;
        height: fit-content;
        font-weight: 300;
        text-align: left;

        padding: 10px 20px;
        // border-radius: 0px 0px 10px 10px;
        border-radius: 30px;

        // background-color: rgba($color: #b5b5b5, $alpha: 1);
        background: rgba(217, 217, 217, 0.1);
    }
}
