#navbar {
  display: block;
  position: fixed;
  width: 100%;
  height: 5em;
  z-index: 5;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: white;
  font-family: "Outfit";
}

#navbar a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: 0.1s all ease;
  transition: 0.1s all ease;
}

#navbar a:hover {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

#navbar a:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#navbar > .navbar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#navbar > .navbar-wrapper > .navbar-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#navbar > .navbar-wrapper > .navbar-logo > img {
  display: block;
  position: relative;
  width: auto;
  height: 80%;
}

#navbar > .navbar-wrapper > .major {
  display: block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#navbar > .navbar-wrapper > .nav-menuitem-left {
  display: block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 20px;
}

#navbar > .navbar-wrapper > .navbar-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#navbar > .navbar-wrapper > .navbar-flex > .nav-menuitem {
  display: block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 1.2em;
  font-weight: 500;
  padding: 10px 20px;
}

#navbar > .navbar-wrapper > .mlh-badge {
  display: block;
  position: relative;
  width: 100px;
  height: auto;
}

#navbar > .navbar-wrapper > .mlh-badge > img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

._mobile-navbar-bg > .navbar-flex {
  background: rgba(255, 255, 255, 0.1);
}

._navbar-bg {
  background: rgba(255, 255, 255, 0.1);
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#mobile-navbar {
  display: block;
  position: fixed;
  width: 100%;
  height: 5em;
  z-index: 5;
  font-size: 16px;
  color: white;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  font-family: "Outfit";
}

#mobile-navbar > .navbar-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: 4.5em;
  z-index: 5;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#mobile-navbar > .navbar-flex .major {
  text-shadow: 0px 0px 5px black;
}

#mobile-navbar > .nav-hamburger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  height: 4em;
  width: 4em;
  margin: 0.5em;
  top: 0;
  right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  z-index: 7;
}

#mobile-navbar > .nav-hamburger > .hamburger-line {
  display: block;
  position: relative;
  height: 3px;
  width: 50%;
  margin: 0.2em auto;
  border-bottom: 3px solid;
  border-color: white;
  border-radius: 2px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

#mobile-navbar > .mobile-navbar-clickoff {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
}

#mobile-navbar > .mobile-navbar-menu {
  display: block;
  position: fixed;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(77, 77, 77, 0.7);
  color: white;
  opacity: 0;
  margin: 10px;
  border-radius: 15px;
  -webkit-animation: fadein 0.5s ease;
          animation: fadein 0.5s ease;
  top: -2000px;
  right: 0;
  z-index: 6;
  -webkit-transition: 0.3s opacity ease;
  transition: 0.3s opacity ease;
  overflow: hidden;
}

#mobile-navbar > .mobile-navbar-menu > .nav-menu-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-top: calc(5em - 20px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1;
}

#mobile-navbar > .mobile-navbar-menu > .nav-menu-flex > .nav-menuitem {
  display: block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 280px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  padding: 10px 5px;
  text-decoration: none;
  color: inherit;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

#mobile-navbar > .mobile-navbar-menu > .nav-menu-flex > .nav-menuitem:hover {
  background-color: rgba(209, 159, 11, 0.3);
}

#mobile-navbar > .mobile-navbar-menu > .nav-menu-flex > .nav-menuitem:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#hamburger-checkbox {
  display: none;
}

#hamburger-checkbox:checked ~ .nav-hamburger {
  -webkit-transform: none;
          transform: none;
}

#hamburger-checkbox:checked ~ .nav-hamburger > .hamburger-line {
  border-color: white;
}

#hamburger-checkbox:checked ~ .nav-hamburger > .hamburger-top {
  -webkit-transform: translateY(calc(0.5em + 1px)) rotate(45deg);
          transform: translateY(calc(0.5em + 1px)) rotate(45deg);
}

#hamburger-checkbox:checked ~ .nav-hamburger > .hamburger-middle {
  opacity: 0;
}

#hamburger-checkbox:checked ~ .nav-hamburger > .hamburger-bottom {
  -webkit-transform: translateY(calc(-0.5em - 1px)) rotate(-45deg);
          transform: translateY(calc(-0.5em - 1px)) rotate(-45deg);
}

#hamburger-checkbox:checked ~ .mobile-navbar-clickoff {
  display: block;
}

#hamburger-checkbox:checked ~ .nav-hamburger ~ .mobile-navbar-menu {
  top: 0;
  opacity: 1;
}
