.event-schedule-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.schedule-tabs {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 1em;

  .schedule-tabs-button {
    font-size: 1.2em;

    background: none;
    color: inherit;
    border: none;
    box-sizing: border-box;

    border-bottom: 2px solid transparent;
    padding: 0.5em;
    cursor: pointer;
    &:hover {
      border-bottom: 2px solid rgba($color: #fff, $alpha: 0.4);
    }
  }
  ._active {
    border-bottom: 2px solid #fff;
    &:hover {
      border-bottom: 2px solid #fff;
    }
  }
}

.schedule-table {
  // add borders to cells
  border-collapse: collapse;
  thead > tr {
    border-bottom: 1px solid #fff;
  }
  tbody tr {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.4);
    &:last-child {
      border-bottom: none;
    }
  }
  th,
  td {
    padding: 0.5em 1em;
  }
}


@media screen and (max-width: 768px) {
  .schedule-table {
    font-size: 11px;
  }
}
