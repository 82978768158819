#navbar {
    display: block;
    position: fixed;
    width: 100%;
    height: 5em;
    z-index: 5;

    transition: 0.3s all ease;

    // border: 1px solid red;

    color: white;

    font-family: "Outfit";

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: 0.1s all ease;

        &:hover {
            filter: brightness(0.8);
        }
        &:active {
            transform: scale(0.95);
        }
    }

    > .navbar-wrapper {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        // border: 1px dashed blue;

        > .navbar-logo {
            display: flex;
            position: relative;
            height: 100%;
            width: fit-content;

            flex-direction: row;
            justify-content: center;
            align-items: center;

            > img {
                display: block;
                position: relative;
                width: auto;
                height: 80%;
            }
        }

        > .major {
            display: block;
            position: relative;
            width: fit-content;
            height: 100%;
            height: fit-content;
        }
        > .nav-menuitem-left {
            display: block;
            position: relative;
            width: fit-content;
            height: fit-content;
            font-size: 1.2em;

            font-weight: 500;
            padding: 10px 20px;

            // border: 1px solid orange;
        }

        > .navbar-flex {
            display: flex;
            position: relative;
            height: 100%;

            flex-grow: 1;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            // border: 1px solid yellow;

            > .nav-menuitem {
                display: block;
                position: relative;
                width: fit-content;
                height: fit-content;
                font-size: 1.2em;

                font-weight: 500;
                padding: 10px 20px;

                // border: 1px solid orange;
            }
        }

        > .mlh-badge {
            display: block;
            position: relative;
            width: 100px;
            height: auto;

            // border: 1px solid red;

            > img {
                display: block;
                position: relative;
                width: 100%;
                height: auto;
            }
        }
    }
}

._mobile-navbar-bg {
    > .navbar-flex {
        background: rgba($color: #fff, $alpha: 0.1);
        // backdrop-filter: blur(3px);
    }
}
._navbar-bg {
    background: rgba($color: #fff, $alpha: 0.1);
    // backdrop-filter: blur(3px);
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#mobile-navbar {
    display: block;
    position: fixed;
    width: 100%;
    height: 5em;
    z-index: 5;
    font-size: 16px;

    // border: 1px solid red;

    color: white;

    transition: 0.3s all ease;

    font-family: "Outfit";
    // background-color: rgba($color: #000000, $alpha: 0.3);

    // border: 1px dashed white;

    > .navbar-flex {
        display: flex;
        position: relative;
        width: 100%;
        height: 4.5em;
        z-index: 5;

        flex-direction: row;
        align-items: center;
        justify-content: center;

        .major {
            text-shadow: 0px 0px 5px black;
        }
    }

    > .nav-hamburger {
        display: flex;
        position: absolute;
        height: 4em;
        width: 4em;
        margin: 0.5em;
        //border: 1px solid white;
        top: 0;
        right: 0;
        flex-flow: column;
        justify-content: center;

        transform: translateY(-0.25em);
        transition: 0.3s all ease;

        z-index: 7;

        > .hamburger-line {
            display: block;
            position: relative;
            height: 3px;
            width: 50%;
            margin: 0.2em auto;
            border-bottom: 3px solid;
            border-color: rgb(255, 255, 255);
            border-radius: 2px;
            transition: 0.3s all ease;
        }

        // > .hamburger-top {
        //     //margin: 2em auto 0 auto;
        // }

        // > .hamburger-bottom {
        //     //margin: 1em auto 0 auto;
        // }
    }
    > .mobile-navbar-clickoff {
        display: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        opacity: 0;
        // border: 3px solid red;
        // background: rgba($color: #fff, $alpha: 0.7);
    }
    > .mobile-navbar-menu {
        display: block;
        position: fixed;
        height: fit-content;
        width: fit-content;

        background-color: rgba($color: #4d4d4d, $alpha: 0.7);
        // backdrop-filter: blur(3px);
        // background: #fff;
        color: rgb(255, 255, 255);
        opacity: 0;

        margin: 10px;
        border-radius: 15px;

        animation: fadein 0.5s ease;

        top: -2000px;
        right: 0;
        z-index: 6;

        transition: 0.3s opacity ease;
        overflow: hidden;

        //border: 1px solid red;

        > .nav-menu-flex {
            display: flex;
            position: relative;
            height: fit-content;
            width: fit-content;

            padding-top: calc(5em - 20px);

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            // gap: 40px;

            z-index: 1;

            > .nav-menuitem {
                display: block;
                position: relative;
                width: fit-content;
                min-width: 280px;
                height: fit-content;
                text-align: center;
                font-size: 1.4em;
                font-weight: 500;
                // margin: 20px;
                padding: 10px 5px;

                text-decoration: none;

                // text-shadow: 0px 0px 5px #000;
                color: inherit;

                transition: 0.3s ease all;
                background: rgba($color: #000, $alpha: 0.2);

                //border: 1px solid red;

                // &:first-of-type {
                //     color: red;
                // }
                border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
                // &:nth-child(n + 2) {

                // }

                &:hover {
                    // filter: invert(0.4);
                    background-color: rgba($color: #d19f0b, $alpha: 0.3);
                }
                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

//#312CC2
//#FFC100

#hamburger-checkbox {
    display: none;
    &:checked ~ .nav-hamburger {
        transform: none;
        > .hamburger-line {
            border-color: rgb(255, 255, 255);
            // border-color: #000;
        }
        > .hamburger-top {
            transform: translateY(calc(0.5em + 1px)) rotate(45deg);
        }
        > .hamburger-middle {
            opacity: 0;
        }
        > .hamburger-bottom {
            transform: translateY(calc(-0.5em - 1px)) rotate(-45deg);
        }
    }

    &:checked ~ .mobile-navbar-clickoff {
        display: block;
    }

    // &:checked ~ .navbar-flex {
    //     color: #FFC100;
    // }

    &:checked ~ .nav-hamburger ~ .mobile-navbar-menu {
        top: 0;
        // height: fit-content;
        opacity: 1;
    }
}
