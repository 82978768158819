#footer {
    display: block;
    position: relative;
    width: 100%;
    height: fit-content;

    // border: 1px dashed #fff;
    background: rgba(255, 255, 255, 0.12);

    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    > .footer-wrapper {
        display: flex;
        position: relative;
        height: fit-content;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px 0;

        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;

        color: #fff;

        > .footer-section {
            display: flex;
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 10px 20px;

            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            > .major {
                display: block;
                position: relative;
                height: fit-content;
                width: fit-content;
                text-align: left;
                font-size: 2em;
                font-weight: 700;
                font-family: "Outfit";
                line-height: 1.2em;
            }
            > .minor {
                display: block;
                position: relative;
                height: fit-content;
                width: fit-content;
                text-align: left;
                font-size: 1.5em;
                font-weight: 500;
                font-family: "Outfit";
                line-height: 1.2em;
            }
            > .mini {
                display: block;
                position: relative;
                height: fit-content;
                width: fit-content;
                text-align: left;
                font-size: 1.2em;
                font-weight: 300;
                font-family: "Outfit";
                line-height: 1.3em;
                margin-left: 15px;
                transition: 0.3s all ease;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
