.home-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-x: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 20px;
}

.home-page a {
  color: inherit;
}

#hero a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

#hero .hero-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 50px;
  padding-top: 5em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#hero .hero-flex > .flex-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: calc(100vh - 5em);
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: #fff;
}

#hero .hero-flex > .flex-left > .hero-major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 7em;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#hero .hero-flex > .flex-left > .hero-minor {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 3em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 0.9em;
  color: rgba(255, 255, 255, 0.8);
  margin: 10px 0 0.5em 0;
}

#hero .hero-flex > .flex-left > .hero-date {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 2em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 0.9em;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
}

#hero .hero-flex > .flex-left > .hero-date-mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
  font-size: 1.5em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 0.9em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 1em;
}

#hero .hero-flex > .flex-left > .hero-button {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.6em;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  padding: 8px 30px 12px 30px;
  border: 6px solid rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

#hero .hero-flex > .flex-left > .hero-button:hover {
  background: #312cc2;
  border: 6px solid #312cc2;
  text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.5);
}

#hero .hero-flex > .flex-left > .hero-button:active {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

#hero .hero-flex > .flex-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: calc(100vh - 5em);
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
}

#hero .hero-flex > .flex-right > .logo-stars {
  display: block;
  position: relative;
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#hero .hero-flex > .flex-right > .logo-stars > .hero-logo {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  -webkit-filter: drop-shadow(0px 0px 100px rgba(79, 75, 218, 0.9));
          filter: drop-shadow(0px 0px 100px rgba(79, 75, 218, 0.9));
}

#hero .hero-flex > .flex-right > .logo-stars > .star {
  display: block;
  position: absolute;
  width: 40px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  -webkit-filter: drop-shadow(0px 0px 50px #f3ae4a);
          filter: drop-shadow(0px 0px 50px #f3ae4a);
}

#hero .hero-flex > .flex-right > .logo-stars > .star-a {
  width: 40px;
  top: -20px;
  right: 40px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  -webkit-animation: StarRotateA 5s infinite;
          animation: StarRotateA 5s infinite;
}

#hero .hero-flex > .flex-right > .logo-stars > .star-b {
  width: 70px;
  top: -40px;
  right: -40px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  -webkit-animation: StarRotateA 5s infinite;
          animation: StarRotateA 5s infinite;
}

#hero .hero-flex > .flex-right > .logo-stars > .star-c {
  width: 40px;
  bottom: -10px;
  left: 40px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  -webkit-animation: StarRotateB 5s infinite;
          animation: StarRotateB 5s infinite;
}

#hero .hero-flex > .flex-right > .logo-stars > .star-d {
  width: 80px;
  bottom: 0px;
  left: -40px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  -webkit-animation: StarRotateB 5s infinite;
          animation: StarRotateB 5s infinite;
}

@-webkit-keyframes StarRotateA {
  0% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@keyframes StarRotateA {
  0% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
}

@-webkit-keyframes StarRotateB {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}

@keyframes StarRotateB {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  100% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}

@-webkit-keyframes hover {
  0% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  50% {
    -webkit-transform: translateY(55px);
            transform: translateY(55px);
  }
  100% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
}

@keyframes hover {
  0% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
  50% {
    -webkit-transform: translateY(55px);
            transform: translateY(55px);
  }
  100% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
  }
}

@-webkit-keyframes hoverhero {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}

@keyframes hoverhero {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
  50% {
    -webkit-transform: translateY(-55px);
            transform: translateY(-55px);
  }
  100% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
  }
}

#about .about-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#about .about-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#about .about-flex > .mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 400;
  font-family: "Outfit";
  line-height: 1.3em;
}

#about .about-flex .telescope {
  display: block;
  position: absolute;
  width: 16em;
  height: auto;
  bottom: -6em;
  left: -13em;
}

#about .about-flex .telescope img {
  width: 100%;
  height: auto;
}

#about .about-flex .moon {
  display: block;
  position: absolute;
  width: 16em;
  height: auto;
  top: -6em;
  right: -13em;
}

#about .about-flex .moon img {
  width: 100%;
  height: auto;
}

#whatis,
#whatis2 {
  margin: 100px 0;
}

#whatis .whatis-flex,
#whatis2 .whatis-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: rgba(217, 217, 217, 0.105);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 102px;
}

#whatis .whatis-flex > .major,
#whatis2 .whatis-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#whatis .whatis-flex > .mini,
#whatis2 .whatis-flex > .mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 400;
  font-family: "Outfit";
  line-height: 1.3em;
}

#whatis .whatis-flex > .star,
#whatis2 .whatis-flex > .star {
  display: block;
  position: absolute;
  width: 3em;
  -webkit-filter: drop-shadow(0px 0px 50px #f3ae4a);
          filter: drop-shadow(0px 0px 50px #f3ae4a);
}

#whatis .whatis-flex > .star-a,
#whatis2 .whatis-flex > .star-a {
  width: 5.5em;
  top: 0;
  left: -2em;
}

#whatis .whatis-flex > .star-b,
#whatis2 .whatis-flex > .star-b {
  width: 2.7em;
  top: 3.5em;
  left: 3em;
}

#whatis .whatis-flex > .star-c,
#whatis2 .whatis-flex > .star-c {
  width: 2.7em;
  top: 6em;
  left: 1em;
}

#whatis .whatis-flex > .star-d,
#whatis2 .whatis-flex > .star-d {
  width: 6em;
  bottom: -0.5em;
  right: -0.5em;
}

#whatis .whatis-flex > .star-e,
#whatis2 .whatis-flex > .star-e {
  width: 2.7em;
  bottom: -1.2em;
  right: 4.3em;
}

#whatis .whatis-flex > .star-f,
#whatis2 .whatis-flex > .star-f {
  width: 2.5em;
  bottom: -2.5em;
  right: -1em;
}

#whenwhere .whenwhere-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#whenwhere .whenwhere-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#whenwhere .whenwhere-flex > .split-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}

#whenwhere .whenwhere-flex > .split-flex > .mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 50%;
  text-align: left;
  font-size: 1.3em;
  font-weight: 400;
  font-family: "Outfit";
  line-height: 1.3em;
}

#whenwhere .whenwhere-flex > .split-flex > .map {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

#faq .faq-flex,
#tracks .faq-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px 0;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#faq .faq-flex > .major,
#tracks .faq-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#schedule {
  color: #fff;
  padding: 100px 0;
  min-height: 70vh;
}

#schedule .schedule-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#schedule .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#tracks .tracks-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px 0;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 1em;
}

#tracks .tracks-flex .clicktoflip {
  font-size: 0.4em;
  font-weight: 400;
  letter-spacing: 0.5px;
}

#tracks .tracks-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#prizes {
  color: #fff;
}

#prizes .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#prizes .prizes-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  padding: 100px 0;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 1em;
}

#prizes .prizes-flex .prize-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

#prizes .prizes-flex .prize-grid > .prize-card {
  display: block;
  position: relative;
  width: 300px;
  min-width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 40px;
  padding-top: 0;
}

#prizes .prizes-flex .prize-grid > .prize-card > .prize-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(217, 217, 217, 0.105);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 52px;
}

#prizes .prizes-flex .prize-grid > .prize-card > img {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
  border-radius: 100%;
}

#prizes .prizes-flex .prize-grid > .prize-card > span {
  width: 100%;
  height: 100%;
}

#prizes .prizes-flex .prize-grid > .prize-card > span > img {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
  border-radius: 100%;
}

#prizes .prizes-flex .prize-grid > .prize-card > .speaker-major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 1em;
  margin: 10px 0;
  color: #f3ae4a;
}

#prizes .prizes-flex .prize-grid > .prize-card > .speaker-mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: left;
  font-size: 1em;
  font-weight: 300;
  font-family: "Outfit";
  line-height: 1.2em;
  margin: 10px 0;
}

#prizes .prizes-flex .prize-grid > .card-down {
  margin-top: 80px;
}

#prizes .prizes-flex .prize-grid > .winner {
  -webkit-filter: drop-shadow(0px 0px 10px rgba(243, 174, 74, 0.5)) drop-shadow(0px 0px 20px rgba(243, 174, 74, 0.5)) drop-shadow(0px 0px 30px rgba(243, 174, 74, 0.5));
          filter: drop-shadow(0px 0px 10px rgba(243, 174, 74, 0.5)) drop-shadow(0px 0px 20px rgba(243, 174, 74, 0.5)) drop-shadow(0px 0px 30px rgba(243, 174, 74, 0.5));
}

#speakers .speakers-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 96%;
  padding: 100px 0;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#speakers .speakers-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#speakers .speakers-flex > .tri-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2em;
}

#speakers .speakers-flex > .tri-flex > .speaker-card {
  display: block;
  position: relative;
  width: 400px;
  min-width: 200px;
  max-width: calc(100% - 6em);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 40px;
  padding-top: 0;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > .speaker-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(217, 217, 217, 0.105);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 52px;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > img {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
  border-radius: 100%;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > span {
  width: 100%;
  height: 100%;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > span > img {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
  border-radius: 100%;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > .speaker-major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  font-family: "Outfit";
  line-height: 1em;
  margin: 10px 0;
  color: #f3ae4a;
}

#speakers .speakers-flex > .tri-flex > .speaker-card > .speaker-mini {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: left;
  font-size: 1em;
  font-weight: 300;
  font-family: "Outfit";
  line-height: 1.2em;
  margin: 10px 0;
}

#speakers .speakers-flex > .tri-flex > .card-down {
  margin-top: 80px;
}

#sponsors .pastsponsors-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 96%;
  padding: 100px 0;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#sponsors .pastsponsors-flex > .major {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  text-align: center;
  font-size: 3em;
  margin-bottom: 40px;
  font-weight: 700;
  font-family: "Outfit";
  line-height: 0.9em;
}

#sponsors .pastsponsors-flex > .splash {
  display: block;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 75px);
  z-index: -1;
  width: 150px;
  height: 150px;
  background-color: #ffc100;
}

#sponsors .pastsponsors-flex > .sponsors-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  padding: 40px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1em;
  background: rgba(255, 255, 255, 0.2);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 41px;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor {
  display: block;
  position: relative;
  height: 10em;
  width: 20em;
  padding: 0.6em;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor > span {
  width: 100%;
  height: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor > span > img {
  display: block;
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-long {
  width: 30%;
  height: auto;
  max-height: 10em;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-long > img {
  width: 100%;
  height: auto;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-long > span {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

#sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-long > span > img {
  width: 100%;
  height: auto;
}

#sponsors .pastsponsors-flex > .sponsor-us-button {
  display: block;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 6px 12px;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  color: rgba(0, 0, 0, 0.8);
  font-family: "Outfit";
  font-weight: 500;
  font-size: 1.3em;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  -webkit-transition: 0.1s all ease;
  transition: 0.1s all ease;
  text-decoration: none;
}

#sponsors .pastsponsors-flex > .sponsor-us-button:hover {
  -webkit-filter: brightness(0.6);
          filter: brightness(0.6);
}

#sponsors .pastsponsors-flex > .sponsor-us-button:active {
  -webkit-transform: translateY(20px) scale(0.95);
          transform: translateY(20px) scale(0.95);
}

#sponsors .waves {
  display: block;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: calc(50% - 50vw);
  width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

#sponsors .waves > span {
  width: 100%;
}

#sponsors .waves > span > img {
  width: 100%;
  height: auto;
}

.continue-reading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  float: right;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: hover 5s infinite;
          animation: hover 5s infinite;
  text-decoration: none;
  color: #fff !important;
  font-family: "Outfit";
  font-size: 1.2em;
  line-height: 1em;
}

.continue-reading:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.continue-reading:hover > .text {
  text-decoration: underline;
}

.continue-reading > .text {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.continue-reading-hero {
  display: none;
  -webkit-animation: hoverhero 5s infinite;
          animation: hoverhero 5s infinite;
}

.mlh-section {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 5em;
  width: 100%;
  margin: 0 auto;
  z-index: 6;
  pointer-events: none;
}

.mlh-section > .mlh-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.mlh-section > .mlh-wrapper > .mlh-badge {
  display: block;
  position: relative;
  width: 100px;
  height: auto;
  pointer-events: auto;
}

.mlh-section > .mlh-wrapper > .mlh-badge > img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.mlh-section > .mlh-wrapper > .mlh-badge > span {
  width: 100%;
  height: auto;
}

.mlh-section > .mlh-wrapper > .mlh-badge > span > img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1400px) {
  .home-page {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .mlh-section > .mlh-wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .mlh-section > .mlh-wrapper > .mlh-badge {
    width: 60px;
    margin: 0 10px;
  }
  #prizes .prizes-flex .prize-grid > .prize-card {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  #prizes .prizes-flex .prize-grid > .winner {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  #prizes .prizes-flex .prize-grid > .card-down {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .home-page {
    font-size: 14px;
  }
  #hero .hero-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 5em;
  }
  #hero .hero-flex > .flex-left {
    height: calc(50vh);
    padding: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  #hero .hero-flex > .flex-right {
    height: calc(50vh - 5em);
    width: 60%;
  }
  #hero .hero-flex > .flex-right > .logo-stars {
    width: 130%;
  }
  #hero .hero-flex > .flex-right > .logo-stars > .star-a {
    width: 30px;
    top: -15px;
    right: 25px;
  }
  #hero .hero-flex > .flex-right > .logo-stars > .star-b {
    width: 50px;
    top: -30px;
    right: -30px;
  }
  #hero .hero-flex > .flex-right > .logo-stars > .star-c {
    width: 30px;
    bottom: -5px;
    left: 25px;
  }
  #hero .hero-flex > .flex-right > .logo-stars > .star-d {
    width: 50px;
    bottom: 0px;
    left: -25px;
  }
  #about .about-flex {
    padding: 100px 0;
  }
  #about .about-flex .telescope {
    left: -17em;
    bottom: -8em;
  }
  #about .about-flex .moon {
    right: -16em;
    top: 4em;
  }
  #whatis .whatis-flex,
  #whatis2 .whatis-flex {
    width: 90%;
    padding: 100px 40px;
  }
  #whenwhere .whenwhere-flex {
    padding: 100px 0;
  }
  #sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-row > .sponsor {
    padding: 5px;
  }
  #sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-row-a {
    height: 80px;
  }
  #sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-row-b {
    height: 60px;
  }
  #sponsors .pastsponsors-flex > .sponsors-flex > .sponsor-row-c {
    height: 50px;
  }
  #speakers .speakers-flex .speaker-card {
    font-size: 1.6em;
  }
  #prizes .prizes-flex .prize-grid > .prize-card {
    font-size: 1.6em;
  }
  #footer {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .home-page {
    font-size: 10px;
  }
  #whenwhere .whenwhere-flex > .split-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #whenwhere .whenwhere-flex > .split-flex > .mini {
    width: 100%;
  }
  #whenwhere .whenwhere-flex > .split-flex > .map {
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }
  .continue-reading {
    display: none;
  }
  .continue-reading-hero {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  #about {
    padding-top: 200px;
  }
  #sponsors .pastsponsors-flex .sponsors-flex > .sponsor-long {
    width: 70%;
  }
  #sponsors .pastsponsors-flex .sponsors-flex > .sponsor {
    width: 30em;
    height: 15em;
  }
}
